require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("parsleyjs")
require("parsleyjs/dist/i18n/ja.js")

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);

import 'controllers/users'

// ======================
// Legacy Javascript
// ======================

// UIkit をグローバル利用可に
window.UIkit = UIkit;

$(document).on('turbolinks:load', () => {

  //
  // Flash表示
  //
  if (window.flash) {
    window.flash.forEach(e => {
        window.UIkit.notification({
            status: e['status'].replace('alert', 'danger'),
            message: e['message'],
            timeout: 6000
        });
    });
    window.flash = [];
  }

})